/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Flex } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import { usePDF } from '@react-pdf/renderer';
import { P2Text } from '../Typography/styled';
import { mvpPdfEmailContent } from '../../utils/mvp-calculator/mvpCalcHelper';
import { getFilteredTechSolutionData } from '../../utils/mvp-calculator/estimate';
import MVPCalculatorResultPDFDocument from './MVPCalculatorResultPDFDocument';
import {
  CONTACT_EMAIL,
  INFO_EMAIL,
  SHEETBEST_CONNECTION_URL,
  SMTP_SECURE_TOKEN,
  OUTREACH_EMAIL,
  DASHAPPS_CONNECTION_URL,
} from '../../constants';
import colors2 from '../../utils/theme/theme2/colors2';
import { DefaultButton } from '../Buttons/styled';
import { MVP_CALCULATOR_REQUIRED_STEPS } from '../../configs/mvp-calculator/MVPCalculatorStepsData';

const Input = styled.input`
  margin-bottom: 20px;
  width: 100%;
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  background-color: ${colors2.gray};
  outline: none;
  border-radius: 10px;

  :focus {
    ::placeholder {
      color: transparent;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;

  button {
    width: 100%;
  }
`;

const styles = {
  title: {
    marginBottom: 10,
    fontSize: 34,
    lineHeight: 1.2,
    fontWeight: 'bold',
    color: '#323347',
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: 40,
    fontSize: 16,
    lineHeight: 1.5,
    color: '#323347',
    opacity: 0.7,
    textAlign: 'center',
  },
  container: {
    background: 'white',
    maxWidth: '610px',
    width: '100%',
  },
  button: {
    marginTop: 20,
    width: '100%',
  },
  text: {
    color: colors2.black,
    fontWeight: '700',
    marginBottom: 30,
    fontSize: '20px',
  },
};

const getProjectIdea = (reportDataBySteps) =>
  reportDataBySteps.find((el) => el.value === MVP_CALCULATOR_REQUIRED_STEPS.PROJECT_IDEA)
    ?.itemsTitles[0];

const MVPCalculatorResultSendPDFForm = ({
  reportDataBySteps,
  totalAmount,
  projectEstimation,
  estimationByMembers,
  handleFormDataWillSent,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const sendPDFtoEmailForm = useRef();

  const { mvpPdfInfo } = useStaticQuery(pdfQuery);
  const pdfImgData = mvpPdfInfo.frontmatter;
  const pdfImgs = useMemo(() => {
    return {
      mvpPdfLogo: pdfImgData.mvpPdfLogo.childImageSharp.fluid.src,
      mvpClockImg: pdfImgData.mvpClockImg.childImageSharp.fluid.src,
      mvpDivider1Img: pdfImgData.mvpDivider1Img.childImageSharp.fluid.src,
      mvpWalletImg: pdfImgData.mvpWalletImg.childImageSharp.fluid.src,
      mvpStarsImg: pdfImgData.mvpStarsImg.childImageSharp.fluid.src,
    };
  }, [
    pdfImgData.mvpClockImg.childImageSharp.fluid.src,
    pdfImgData.mvpDivider1Img.childImageSharp.fluid.src,
    pdfImgData.mvpPdfLogo.childImageSharp.fluid.src,
    pdfImgData.mvpStarsImg.childImageSharp.fluid.src,
    pdfImgData.mvpWalletImg.childImageSharp.fluid.src,
  ]);

  const reportDataByStepsFiltered = reportDataBySteps.filter((el) => el.stepCost !== 0);
  const projectIdea = getProjectIdea(reportDataBySteps);

  const [instance, updateInstance] = usePDF();
  const [isDelayingPDFCreating, setIsDelayingPDFCreating] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsDelayingPDFCreating(false);
    }, 1500);
  }, []);

  useEffect(() => {
    updateInstance(
      <MVPCalculatorResultPDFDocument
        reportDataBySteps={reportDataByStepsFiltered}
        projectIdea={projectIdea}
        totalAmount={totalAmount}
        projectEstimation={projectEstimation}
        estimationByMembers={estimationByMembers}
        pdfImgs={pdfImgs}
        date={new Date()}
        technicalSolutions={getFilteredTechSolutionData(reportDataBySteps)}
      />
    );
  }, [
    projectEstimation,
    reportDataBySteps,
    totalAmount,
    estimationByMembers,
    updateInstance,
    reportDataByStepsFiltered,
    pdfImgs,
  ]);

  const reportDataByStepsValues = reportDataBySteps
    .map(
      (step, i) =>
        `<STEP-${i + 1}>  ${step.value}: ${step.itemsTitles.join(', ')}  cost: ${step.cost}; `
    )
    .join('\n');

  if (instance.error) return <div>Something went wrong</div>;

  const mvpPDFfile = new File([instance.blob], 'DashbouquetMVP.pdf', {
    lastModified: new Date().getTime(),
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const sendEmail = (toEmail, fromEmail, copyEmails, convertedPDF) => {
    if (window.Email) {
      window.Email.send({
        SecureToken: SMTP_SECURE_TOKEN,
        To: toEmail,
        From: fromEmail,
        Cc: ['ak@dashbouquet.com'],
        Bcc: copyEmails,
        Subject: 'Dashbouquet MVP Calculation result',
        Body: mvpPdfEmailContent(name),
        Attachments: [
          {
            name: 'DashbouquetMVP.pdf',
            data: convertedPDF,
          },
        ],
      }).then((message) => console.log(message));
    }
  };
  async function onConvertFileToBase64() {
    let result = `${await toBase64(mvpPDFfile).catch((e) => Error(e))}`;
    result = result.substring(result.lastIndexOf(',') + 1);
    if (result instanceof Error) {
      console.log('Error: ', result.message);
      return;
    }
    sendEmail([email], INFO_EMAIL, [CONTACT_EMAIL, OUTREACH_EMAIL], result);
  }

  const date = new Date();
  const dataToSend = {
    Name: name,
    Email: email,
    Date: date,
    DataByStepsValues: reportDataByStepsValues,
    TotalAmount: totalAmount,
  };

  const sendDataToGoogleSheets = () => {
    axios
      .post(`${SHEETBEST_CONNECTION_URL}/tabs/MVPRequests`, dataToSend)
      .then((_response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const sendDataToDashapps = () => {
    axios
      .post(DASHAPPS_CONNECTION_URL, dataToSend)
      .then((_response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitPDFrequest = (e) => {
    e.preventDefault();
    sendDataToDashapps();
    onConvertFileToBase64();
    handleFormDataWillSent();
    // sendDataToGoogleSheets();
  };

  const isLoading = isDelayingPDFCreating || instance.loading;

  return (
    <Flex
      as="form"
      name="SendPDFtoEmail"
      ref={sendPDFtoEmailForm}
      flexDirection="column"
      alignItems="center"
      width={[280, 360]}
      paddingTop="30px"
      onSubmit={handleSubmitPDFrequest}
      style={styles.container}
    >
      <P2Text style={{ textAlign: 'center', marginBottom: '40px' }} color={colors2.lightText}>
        Leave your contact info to get a detailed PDF with costs, features, time, and technical
        solution for your project.
      </P2Text>
      <Input
        type="text"
        name="user_name"
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
        required
      />
      <Input
        type="email"
        name="user_email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <ButtonContainer>
        <DefaultButton type="submit" height="56px" disabled={isLoading}>
          {isLoading ? 'We are creating your PDF ...' : 'GET PDF'}
        </DefaultButton>
      </ButtonContainer>
    </Flex>
  );
};

MVPCalculatorResultSendPDFForm.propTypes = {
  handleFormDataWillSent: PropTypes.func.isRequired,
};

MVPCalculatorResultSendPDFForm.defaultProps = {};

export default MVPCalculatorResultSendPDFForm;

export const pdfQuery = graphql`
  {
    mvpPdfInfo: markdownRemark(frontmatter: { collection_name: { eq: "mvpCalc" } }) {
      frontmatter {
        mvpPdfLogo {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        mvpClockImg {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        mvpDivider1Img {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        mvpWalletImg {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        mvpStarsImg {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
